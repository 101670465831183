import React from "react";
import { Link } from "gatsby";
import "./header.scss";

class Header extends React.Component {
  render() {
    const main = this.props.subheader ? (
      <header>
        <Link to="/">
          <h2 style={{ marginBottom: 0 }}>Christopher Andersson</h2>
        </Link>
        <Link to="/writing">
          <h1 style={{ marginTop: 0 }}>/Writing</h1>
        </Link>
      </header>
    ) : (
      <header>
        <h1>Christopher Andersson</h1>
      </header>
    );
    return <div>{main}</div>;
  }
}

export default Header;
