import React from "react";
import { Link } from "gatsby";
import "./footer.scss";

class Footer extends React.Component {
  render() {
    return (
      <div className="contact">
        <ul>
          <li>
            <Link to="/writing">writing</Link>
          </li>
          <li>
            <a href="https://github.com/chrisanderss0n">github</a>
          </li>
          <li>
            <a href="https://twitter.com/chrisanderss0n">twitter</a>
          </li>
          <li>
            <a href="https://linkedin.com/in/christopherandersson">linkedin</a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Footer;
